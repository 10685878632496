import { FC } from 'react';
import IconProps from './IconProps';

const WarningInfoIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.32171 13.679C11.7354 9.40808 12.9422 7.2726 14.5983 6.7229C15.5093 6.42049 16.4907 6.42049 17.4017 6.7229C19.0578 7.2726 20.2646 9.40808 22.6783 13.679C25.092 17.9499 26.2988 20.0854 25.9368 21.8254C25.7376 22.7827 25.2469 23.6509 24.535 24.3058C23.241 25.4961 20.8274 25.4961 16 25.4961C11.1727 25.4961 8.75897 25.4961 7.46496 24.3058C6.75308 23.6509 6.26239 22.7827 6.06322 21.8254C5.70119 20.0854 6.90803 17.9499 9.32171 13.679Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M16.2422 21V17C16.2422 16.5286 16.2422 16.2929 16.0957 16.1464C15.9493 16 15.7136 16 15.2422 16"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M15.9922 13H16.0012" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default WarningInfoIcon;
